<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Poliklinik</b> Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/polyclinic/add')">Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>

            <!-- Filter -->
            <div class="row align-items-center justify-content-end mt-n3">
              <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Poliklinik"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              responsive
              class="mt-3"
              :items="items"
              :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }" />
              </template>
              <template #cell(actions)='data'>
                <div class="d-flex">
                  <b-button
                    size="sm"
                    class="mr-1 btn-primary"
                    variant="primary"
                    v-b-tooltip.hover
                    title="Dokter"
                    @click="setSelectedPolyclinic(data.item.id, data.item.name)"
                    v-b-modal.modal-doctor><i class="fas fa-user-md px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="$router.push({ path: '/polyclinic/detail/' + data.item.id })"><i class="fas fa-eye px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="$router.push({ path: '/polyclinic/edit/' + data.item.id })"><i class="fas fa-edit px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <b-modal
      id="modal-doctor"
      :title="`Daftar Dokter ${selectedPolyclinic.name}`"
      hide-footer
      size="xl"
      @hidden="modalDoctorOnHide">
      <Table
        purpose="polyclinic-modal"
        :polyclinicId="selectedPolyclinic.id" />
    </b-modal>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Table from "@/component/doctors/Table.vue"
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
          tdClass: 'text-truncate'
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Other
      selectedPolyclinic: {
        id: "",
        name: "",
      },
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate("polyclinics", `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    setSelectedPolyclinic(id, name) {
      this.selectedPolyclinic.id = id
      this.selectedPolyclinic.name = name
      console.log(this.selectedPolyclinic)
    },

    modalDoctorOnHide() {
      this.selectedPolyclinic = { id: "", name: "" }
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("polyclinics/" + id);
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Poliklinik" },
      { title: "Daftar Poliklinik" },
    ]);
    // Get Data
    this.pagination()
  },

}
</script>
